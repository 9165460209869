import { MdFileDownload } from 'react-icons/md'
import ProjectTrackerList from '../../../ProjectTrackerList'
import ProjectTrackerExportButton from '../../../ProjectTrackerExportButton'
import ProjectTrackerFilter from '../../../ProjectTrackerFilter'
import ProjectTrackerGraphs from '../../../ProjectTrackerGraphs'
import Button from '../../components/Button'
import { buildExcelReport } from '../../../ForecastingReport'
import { hasForecastingReport } from '@/config/feature-flags'

const MainTracker = () => {
  return (
    <div id="project-tracker">
      <div className="section-header">
        <div className="container">
          <div className="row section-title">
            <div className="columns twelve">
              <h3>Project Tracker</h3>
            </div>
          </div>
          <div className="row">
            <div className="columns three filters">
              <ProjectTrackerFilter />
            </div>
          </div>
          <ProjectTrackerGraphs />
        </div>
      </div>
      <div className="container">
        {hasForecastingReport ? (
          <div className="row">
            <div className="columns eight">
              <span>Performance Report</span>
            </div>
            <div className="columns four align-right hide-mobile">
              <div className="one-half column">
                <ProjectTrackerExportButton />
              </div>
              <div className="one-half column">
                <Button
                  label="Forecast Report"
                  onClick={buildExcelReport}
                  icon={<MdFileDownload />}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="columns six">
              <span>Performance Report</span>
            </div>
            <div className="columns six align-right hide-mobile">
              <ProjectTrackerExportButton />
            </div>
          </div>
        )}

        <div className="row">
          <div className="columns twelve">
            <ProjectTrackerList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainTracker
