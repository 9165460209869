import React, { useCallback } from 'react'
import { FaCheckSquare } from 'react-icons/fa'
import { TbSquareX } from 'react-icons/tb'
import { TbArrowsSplit } from 'react-icons/tb'
import { TriageStatus } from '../types'

type CellEditorRegularProps = {
  pairCount: number
  onValueChange: (value: TriageStatus | string) => void
  stopEditing: (suppressNavigateAfterEdit?: boolean) => void
}

const CellEditorRegular = ({
  pairCount,
  onValueChange,
  stopEditing,
}: CellEditorRegularProps) => {
  const handleAccept = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      onValueChange('ACCEPTED')
      stopEditing()
    },
    [pairCount, onValueChange, stopEditing],
  )

  const handleReject = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      onValueChange('REJECTED')
      stopEditing()
    },
    [onValueChange, stopEditing],
  )

  return (
    <>
      {pairCount ? (
        <div className="ctrlbtn split">
          <div className="iconbox" onClick={handleAccept}>
            <TbArrowsSplit size={30} />
          </div>
          <span>Accept</span>
        </div>
      ) : (
        <div className="ctrlbtn accept">
          <div className="iconbox" onClick={handleAccept}>
            <FaCheckSquare size={36} />
          </div>
          <span>Accept</span>
        </div>
      )}
      <div className="ctrlbtn reject">
        <div className="iconbox" onClick={handleReject}>
          <TbSquareX size={39} />
        </div>
        <span>Reject</span>
      </div>
    </>
  )
}

export default CellEditorRegular
