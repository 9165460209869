import Moment from 'moment'
import { Lead as LeadLabels, Goal as GoalLabels } from './property-labels'

export const aggregate = (activities) => {
  return activities.map((activity) => {
    const { createdAt, diff, isMultiDiff, lead, targets } = activity

    let labels

    switch (targets[0].__typename) {
      case 'Lead':
        labels = LeadLabels
        break
      case 'Goal':
        labels = GoalLabels
        break
    }

    const res = {
      ...activity,
      lead: {
        ...lead,
        closedAt: lead.closedAt
          ? Moment(lead.closedAt).format('MMMM D, YYYY')
          : lead.closedAt,
      },
      createdAt: Moment(createdAt).format(
        isMultiDiff ? 'MMM D, YYYY' : 'MMM D, YYYY h:mm a',
      ),
      changes: [],
    }

    diff &&
      Object.keys(diff)
        .filter((k) => !['diff', 'id', 'wgnetProjectId'].includes(k)) // non-lead attributes that may be encountered in activities table that we want to keep without breaking ui.
        .forEach((key) => {
          targets.forEach((target) => {
            if (diff[key]) {
              const property = labels[key]
              const label = property.label
              const value = property.value
                ? property.value(target[key])
                : target[key]

              const exisitngChange = res.changes.find(
                (change) => change.label === label,
              )
              if (!exisitngChange) {
                res.changes.push({
                  label,
                  value,
                })
              }
            }
          })
        })

    return res
  })
}
