import { MdLocationOn } from 'react-icons/md'
import { GQLProject } from '../types'

export type ProjectBoxProps = {
  value: GQLProject
}

export default ({ value: project }: ProjectBoxProps) => {
  const address = [
    project.address?.street1,
    project.address?.street2,
    project.address?.postalCode,
    project.address?.city,
    project.address?.province,
  ]
    .filter(Boolean)
    .join(', ')
  const { unitsNumber } = project
  return (
    <div className="triage-item-cell">
      <div className="celltitle">{project.name}</div>
      <div className="detail units">
        <strong>{unitsNumber} units</strong>
      </div>
      <div className="detail">
        <span className="label">Principal</span>
        <span className="value">{project.principal?.name}</span>
      </div>
      <div className="detail">
        <span className="label">Company</span>
        <span className="value">{project.principal?.company?.name}</span>
      </div>
      <div className="locator">
        <MdLocationOn size={20} /> {address}
      </div>
    </div>
  )
}
