import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import MainTracker from './MainTracker'
import PipelineReport from './PipelineReport'
import 'react-tabs/style/react-tabs.scss'
import './styles.scss'

const ProjectTracker = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const switchTabs = (index, lastIndex) => {
    if (index !== lastIndex) {
      return setTabIndex(index)
    }

    // not switching.
    return false
  }

  return (
    <Tabs selectedIndex={tabIndex} onSelect={switchTabs}>
      <TabList>
        <Tab>Main Tracker</Tab>
        <Tab>Pipeline Report</Tab>
      </TabList>

      <TabPanel forceRender={true}>
        <MainTracker />
      </TabPanel>
      <TabPanel>
        <PipelineReport />
      </TabPanel>
    </Tabs>
  )
}

export default ProjectTracker
