import { GQLPairing } from '../types'
import { FaCheckSquare } from 'react-icons/fa'
import { TbSquareX } from 'react-icons/tb'
import { TbArrowsSplit } from 'react-icons/tb'

export type TriageStatusProps = {
  value: GQLPairing
}

export default ({ value: pairing }: TriageStatusProps) => {
  const {
    lead: {
      multiplicity: { pairCount },
    },
  } = pairing

  return (
    <div className="triage-item-cell controls readonly">
      {pairCount ? (
        <div className="ctrlbtn split">
          <div className="iconbox">
            <TbArrowsSplit size={30} />
          </div>
        </div>
      ) : (
        <div className="ctrlbtn accept">
          <div className="iconbox">
            <FaCheckSquare size={36} />
          </div>
        </div>
      )}
      <div className="ctrlbtn reject">
        <div className="iconbox">
          <TbSquareX size={39} />
        </div>
      </div>
    </div>
  )
}
