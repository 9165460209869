import { memo, useRef } from 'react'
import { CustomCellEditorProps } from 'ag-grid-react'
import { GQLPairing, TriageStatus } from '../types'
import CellEditorRegular from './CellEditorRegular'

export interface TriageControlsProps extends CustomCellEditorProps {
  value: GQLPairing
  onValueChange: (value: TriageStatus | string) => void
  stopEditing: (suppressNavigateAfterEdit?: boolean) => void
}

export default memo(
  ({ value: pairing, onValueChange, stopEditing }: TriageControlsProps) => {
    const {
      lead: {
        multiplicity: { pairCount },
      },
    } = pairing
    const refContainer = useRef<HTMLDivElement>(null)

    return (
      <div
        ref={refContainer}
        tabIndex={1} // important - without this the key presses wont be caught (per docs)
        className="triage-item-cell controls">
        <CellEditorRegular
          pairCount={pairCount}
          onValueChange={onValueChange}
          stopEditing={stopEditing}
        />
      </div>
    )
  },
)
