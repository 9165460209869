import { Column, Workbook, Worksheet } from 'exceljs'
import { ProjectDataObj, ProjectSummaryObj } from './types'
import { toProjectForecastObj, toProjectSummaryObj } from './mappers'
import {
  projectsMainTable,
  projectsSummaryTable,
  disclaimersTable,
  reportConfigTable,
  retrofitFormulas,
} from './tableBuilders'
import { assumptionsMatrix } from './assumptions'
import { useSampleDataForExcel } from '@/config/feature-flags'
import { keySort, makeCellCursor, toSegments } from './utils'
import { projectSummaryHeaders } from './columnHeaders'

export const buildProjectForecastReports = (
  workbook: Workbook,
  rows: ProjectDataObj[],
  messages: string[],
) => {
  const prjSheet: Worksheet = workbook.addWorksheet('Project Forecasting')
  const prjSummarySheet: Worksheet = workbook.addWorksheet(
    'Project Forecast Summary',
  )
  const {
    tarionDepositProtectionAmt,
    depositRate,
    projectForecast: { averagePricePerUnit },
    unitsComingToMarketRate,
    unitsWGMarketShare,
  } = assumptionsMatrix

  // main table
  const mainTableRows = rows?.map((project) => toProjectForecastObj(project))
  projectsMainTable(prjSheet, mainTableRows)
  retrofitFormulas(
    prjSheet.getColumn('tarionBond'),
    (_cell, rownum) => `C${rownum}*${tarionDepositProtectionAmt.cellRef}`,
  )
  retrofitFormulas(
    prjSheet.getColumn('potentialDiFacility'),
    (_cell, rownum) =>
      `(C${rownum}*${depositRate.cellRef}*${averagePricePerUnit.cellRef})-(C${rownum}*${tarionDepositProtectionAmt.cellRef})`,
  )
  retrofitFormulas(
    prjSheet.getColumn('averageUnitPrice'),
    () => averagePricePerUnit.cellRef,
  )
  retrofitFormulas(
    prjSheet.getColumn('averageRevenues'),
    (_cell, rownum) => `J${rownum}*C${rownum}`,
  )

  // summary table with breakdown into segments
  const clientSegments = toSegments(mainTableRows, 'clientType')
  const offsetX = 1
  const gapY = 1

  Object.keys(clientSegments).forEach((name) => {
    const nextY = prjSummarySheet.rowCount + gapY
    const cursor = makeCellCursor(prjSummarySheet.getCell(nextY, offsetX))

    if (name) {
      const segmentRows = clientSegments[name]

      // sort keys for consistent ordering in sub-grids
      const salesStatusSegments = keySort(
        toSegments(segmentRows, 'salesStatus'),
      )

      const summaryRows: ProjectSummaryObj[] = []
      Object.keys(salesStatusSegments).forEach((status) => {
        const innerRows = salesStatusSegments[status]
        const segmentSummaryObj = toProjectSummaryObj(
          innerRows,
          assumptionsMatrix,
        )
        summaryRows.push(segmentSummaryObj)
      })
      projectsSummaryTable(cursor, summaryRows)
    }
  })

  const skips = [''] // skip blank cells too
  const targetColumns: { [key: string]: Column } = {}
  const colRefs = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']
  projectSummaryHeaders.forEach((item) => {
    skips.push(item.header)
    const colRef = colRefs.shift()
    colRef && (targetColumns[item.key] = prjSummarySheet.getColumn(colRef))
  })
  retrofitFormulas(
    targetColumns.tarionBond,
    (_cell, rownum) => `C${rownum}*${tarionDepositProtectionAmt.cellRef}`,
    skips,
  )
  retrofitFormulas(
    targetColumns.potentialDiFacility,
    (_cell, rownum) =>
      `(C${rownum}*${depositRate.cellRef}*${averagePricePerUnit.cellRef})-(C${rownum}*${tarionDepositProtectionAmt.cellRef})`,
    skips,
  )
  retrofitFormulas(
    targetColumns.totalFacilities,
    (_cell, rownum) => `D${rownum} + E${rownum}`,
    skips,
  )
  retrofitFormulas(
    targetColumns.unitsComingToMarket,
    (_cell, rownum) => `${unitsComingToMarketRate.cellRef} * C${rownum}`,
    skips,
  )
  retrofitFormulas(
    targetColumns.unitsMarketShare,
    (_cell, rownum) => `${unitsWGMarketShare.cellRef} * G${rownum}`,
    skips,
  )
  retrofitFormulas(
    targetColumns.wgTarionBond,
    (_cell, rownum) => `${tarionDepositProtectionAmt.cellRef} * H${rownum}`,
    skips,
  )
  retrofitFormulas(
    targetColumns.wgDiFacility,
    (_cell, rownum) =>
      `(H${rownum}*${depositRate.cellRef}*${averagePricePerUnit.cellRef})-I${rownum}`,
    skips,
  )
  retrofitFormulas(
    targetColumns.wgTotalFacilities,
    (_cell, rownum) => `I${rownum}+J${rownum}`,
    skips,
  )

  // print messages / disclaimers - main table
  let offsetY = 5
  let rowIndex = prjSheet.rowCount + offsetY
  let startCell = prjSheet.getCell(rowIndex, 1)
  disclaimersTable(startCell, messages)

  // print messages / disclaimers - summary table breakdown
  const summaryTableNotes = [
    `Number of Projects: should match the number of rows when the main project forecast tab is filtered by the given client type and sales status.`,
    `Unit Count: should match the sum total of units across all matching rows when the main project forecast tab is filtered by the given client type and sales status.`,
    `For this breakdown (by client type and sales status), the same ratios are used for WG market share & units coming to market (see coversheet) regardless of the segment being aggregated.`,
  ]
  offsetY = 5
  rowIndex = prjSummarySheet.rowCount + offsetY
  startCell = prjSummarySheet.getCell(rowIndex, 1)
  disclaimersTable(startCell, summaryTableNotes)
}

export const buildCoverReport = (workbook: Workbook) => {
  const coverSheet: Worksheet = workbook.addWorksheet('Coversheet')
  const title = coverSheet.getCell('C5')

  title.value = 'WGL Forecasting Reports'
  title.font = {
    size: 16,
    bold: true,
  }
  const subTitle = coverSheet.getCell('C7')
  subTitle.value = 'Auto-generated by WG Leads App'
  title.font = {
    size: 14,
    bold: true,
  }

  // reportConfigTable cell addresses are fixed, MUST start at C9; used by retrofitFormulas(), referenced by assumptionsMatrix.
  const startCell = coverSheet.getCell(`C9`)
  const tableData = assumptionsMatrix.toHumanReadableFlatTable()
  reportConfigTable(startCell, tableData)

  if (useSampleDataForExcel) {
    const startRow = coverSheet.rowCount + 2
    const warning = coverSheet.getCell(`C${startRow}`)
    warning.font = {
      color: { argb: 'FF0000' },
      bold: true,
    }
    warning.value =
      '⚠️ This report was built using sample data for demonstration purposes.'
  }
}
