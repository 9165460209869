import React, { useState, useEffect } from 'react'
import { getSessionKey } from '../../../../common/local-storage'
import axios from 'axios'

const PipelineReport = () => {
  const [iframeUrl, setIframeUrl] = useState(null)

  useEffect(() => {
    const fetchIframeUrl = async () => {
      try {
        const dashboardId = parseInt(
          import.meta.env.REACT_APP_MBASE_PIPELINE_DASHBOARD_ID,
          10,
        )
        if (isNaN(dashboardId))
          throw new Error('Invalid dashboard id, or no value set.')

        const token = getSessionKey()
        if (!token) throw new Error('No token found')

        const auth = `Bearer ${token}`
        const response = await axios({
          method: 'post',
          url: `${import.meta.env.REACT_APP_API_URL}/metabase`,
          data: {
            dashboardId,
            dashboardParams: {},
          },
          headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
          },
        })
        setIframeUrl(response.data.embedUrl)
      } catch (error) {
        console.error('Error fetching iframe URL:', error)
      }
    }

    fetchIframeUrl()
  }, [setIframeUrl])

  return (
    <div id="mbase-embed">
      {!iframeUrl && <div>Loading...</div>}
      {iframeUrl && <iframe src={iframeUrl} width="100%" height="100%" />}
    </div>
  )
}

export default PipelineReport
